body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  /* font-family: 'Noto Sans', sans-serif; */
  background-color: #070f26;
  background-image: url("./Images/BgBody.jpg");
}
@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: 'gok_stencilregular';
  src: url('./fonts/gok_stencil-webfont.woff2') format('woff2'),
       url('./fonts/gok_stencil-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

.TextSchedule {
  font-family: 'gok_stencilregular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ButtonTeste {
  width: 200px;
  height: 200px
}

img {
  width: 100%;
  height: auto
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.FormContato {
  display: flex;
  flex-direction: column;
  margin-top: -15px;
}

.InputForm {
  font-size: 18px;
  font-weight: 300;
  border: 3px solid #3050aa;
  height: 40px;
  background-color: transparent;
  margin-top: 15px;
  border-radius: 27px;
  padding-left: 25px;
  color: #fff;
}

.InputTextArea {
  font-size: 18px;
  font-weight: 300;
  border: 3px solid #3050aa;
  height: 145px;
  background-color: transparent;
  margin-top: 15px;
  border-radius: 27px;
  padding-left: 25px;
  padding-top: 10px;
  color: #fff;
}

.BotaoEnvia {
  background-color: #446fe7;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0px;
  border-radius: 26px;
  cursor: pointer;
}

.DanielApp2 {
  margin-top: -100px; 
  position: absolute;
}

.AlertaJs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 246px;
  background-color: #070f26;
  justify-content: space-around;
  border-radius: 13px;
}

.BotaoAcessarBD {
  background-color: #446fe7;
  width: 250px;
  height: 41px;
  border: 0px;
  border-radius: 12px;
  font-family: 'Poppins';
  font-weight: bold;
  color: #fff;
  font-size: 19px;
}

.Iframe {
  width: 100%;
  height: 40vw;
}


.SliderBanner1 {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.SliderDisco .slick-dots {
  display: none !important;
}

.slick-prev {
  display: block !important;
  width: 43px !important;
  height: 37px !important;
  left: -101px !important;
}

.slick-next {
  display: block !important;
  width: 43px !important;
  height: 37px !important;
  right: -101px !important;
}

.SliderDisco .slick-prev {
  display: block !important;
  width: 43px;
  height: 37px;
  left: -101px;
}

.SliderDisco .slick-next {
  display: block !important;
  width: 43px;
  height: 37px;
  right: -101px;
}

.slick-prev:before, .slick-next:before {
  color: #446fe7 !important;
  font-size: 42px !important;
}

.slick-dots li {
  width: 45px !important;
  height: 10px !important;
}

.slick-dots li button {
  border: 2px solid #243f88 !important;
  border-radius: 7px !important;
  width: 45px !important;
  height: 10px !important;
}

.slick-dots li button:hover {
  background-color: #243f88 !important;
}

.slick-dots li button:before {
  opacity: 0 !important;
}

.ItemCalls1, .ItemCalls2 , .ItemCalls3, .ItemCalls4 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ItemCalls1 {
  background-image: url("./Images/ImgCalls1.jpg");
}

.ItemCalls2 {
  background-image: url("./Images/ImgCalls2.jpg");
}

.ItemCalls3 {
  background-image: url("./Images/ImgCalls3.jpg");
}

.ItemCalls4 {
  background-image: url("./Images/ImgCalls4.jpg");
}

.ItemCalls1:hover {
  border-radius: 12px;
  background-image: url("./Images/ImgCalls2Hover1.jpg");
}

.ItemCalls2:hover {
  border-radius: 12px;
  background-image: url("./Images/ImgCalls2Hover2.jpg");
}

.ItemCalls3:hover {
  border-radius: 12px;
  background-image: url("./Images/ImgCalls2Hover3.jpg");
}

.ItemCalls4:hover {
  border-radius: 12px;
  background-image: url("./Images/ImgCalls2Hover4.jpg");
}

.ImgBannerResp {
  display: none;
}



.slick-initialized .slick-slide, .SliderDisco  {
  display: block !important;
  /* width: 10px !important; */
}

.IframeSpot {
  height: 20.6vw;
}

.IframeVideo {
  width: 958px;
  height: 540px;
}

/* @media (max-width: 1706px) {
  .IframeSpot {
    height: 21.6vw;
  }
}

@media (max-width: 1627px) {
  .IframeSpot {
    height: 22.6vw;
  }
}

@media (max-width: 1555px) {
  .IframeSpot {
    height: 23.6vw;
  }
}

@media (max-width: 1489px) {
  .IframeSpot {
    height: 24.6vw;
  }
}

@media (max-width: 1428px) {
  .IframeSpot {
    height: 25.6vw;
  }
}

@media (max-width: 1373px) {
  .IframeSpot {
    height: 26.6vw;
  }
}

@media (max-width: 1321px) {
  .IframeSpot {
    height: 19.6vw;
  }
} */

@media (max-width: 1350px) {
.slick-prev {
  left: -55px !important;
}

.slick-next {
  right: -55px !important;
}

.SliderDisco .slick-prev {
  left: -101px !important;
}

.SliderDisco .slick-next {
  right: -101px !important;
}

}

@media (max-width: 1220px) {
  .BotaoEnvia {
    width: 300px;
    margin-top: 20px;
  }
}

@media (max-width: 1152px) {
  .LeftItemSchedule2 {
    width: 100% !important;
  }

  .LeftContentSchedule3 {
    display: none !important;
  }

}

@media (max-width: 1100px) {
  .SliderBanner1 {
    padding-bottom: 10px;
  }

  .IframeVideo {
    width: 650px;
    height: 365px;
  }
}

@media (max-width: 950px) {
  .IframeSpot {
    height: 44.6vw;
  }

  .SliderDisco .slick-next {
    right: -10vw !important;
  }

  .SliderDisco .slick-prev {
    left: -10vw !important;
  }
}

@media (max-width: 930px) {
  .ImgPartner5 {
    padding-top: 33px;
  }
}

@media (max-width: 900px) {
  .LeftItemSchedule2 {
    width: 100% !important;
    margin-top: 0px !important;
  }
}

@media (max-width: 850px) {
  .ImgBanner {
    display: none;
  }
  
  .ImgBannerResp {
    display: block;
  }
}

@media (max-width: 788px) {
  .IframeSpot {
    height: 52.6vw;
  }
}

@media (max-width: 740px) {
  .IframeVideo {
    width: 90%;
    height: 51vw;
  }
}

@media (max-width: 700px) {
  .slick-dots li {
    width: 30px !important;
    height: 5px !important;
  }

  .slick-dots li button {
    width: 30px !important;
    height: 5px !important;
  }

  .Iframe {
    height: 60vw;
  }
}

@media (max-width: 668px) {
  .IframeSpot {
    height: 62.6vw;
  }
}

@media (max-width: 561px) {
  .IframeSpot {
    height: 173px;
  }
}

@media (max-width: 550px) {
 .slick-next {
    right: -12vw !important;
    width: 34px !important;
    height: 30px !important;
  }
  
  .slick-prev {
    left: -12vw !important;
    width: 34px !important;
    height: 30px !important;
  } 
  
  .SliderDisco .slick-next {
    right: -12vw !important;
    width: 34px !important;
    height: 30px !important;
  }
  
  .SliderDisco .slick-prev {
    left: -12vw !important;
    width: 34px !important;
    height: 30px !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 33px !important;
  }

  .slick-dots {
    bottom: -44px !important;
  }
}

@media (max-width: 500px) {
  /* .BotaoEnvia {
    width: 225px;
    height: 45px;
    margin-top: 10px;
  } */

  .InputForm {
    margin-top: 10px;
  }

  .InputTextArea {
    margin-top: 10px;
  }

  .react-confirm-alert {
    width: 90%;
  }

  .AlertaJs {
    width: 90%;
    height: 246px;
    padding-left: 18px;
    padding-right: 18px;
  }
}


